.carouselAnimation-appear {
  opacity: 0;
}
.carouselAnimation-appear-active,
.carouselAnimation-appear-done {
  opacity: 1;
}

.carouselAnimation-appear-active {
  transition: opacity 300ms ease;
}

.serviceMobile-enter {
  opacity: 0;
}
.serviceMobile-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.blockAnimation-enter {
  opacity: 0;
}
.blockAnimation-enter-active,
.blockAnimation-enter-done {
  opacity: 1;
}

.blockAnimation-enter-active {
  transition: opacity 300ms;
}
