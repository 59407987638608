@import '../style/topography';

.wndContainer {
  margin: auto;
  white-space: pre-wrap;
  text-align: left;
}

.dialogOverlay,
.modalOverlay {
  z-index: 1500;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.dialogOverlay.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 300ms ease-in;
}
.dialogOverlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5);
}
.dialogOverlay.ReactModal__Overlay--before-close{
  background-color: rgba(0, 0, 0, 0.1);
}

.dialogContent, 
.modalContent {
  position: absolute;
  background-color: #ffffff;
}

.dialogContent.clearBackground, 
.modalContent.clearBackground {
  background-color: $clear;
}

.dialogContent.transparentBackground, 
.modalContent.transparentBackground {
  background: none;
}

.dialogContent.blackBackground, 
.modalContent.blackBackground {
  background: $black;
}

.dialogContent:focus,
.modalContent:focus {
  outline: none;
}

.side__left {
  left: 0px;
}
.side__left.ReactModal__Content {
  transform: translateX(-100%);
  transition: transform 300ms ease;
}
.side__left.ReactModal__Content--after-open {
  transform: translateX(0);
}
.side__left.ReactModal__Content--before-close{
  transform: translateX(-100%);
}

.side__right {
  right: 0px;
}
.side__right.ReactModal__Content {
  transform: translateX(100%);
  transition: transform 300ms ease;
}
.side__right.ReactModal__Content--after-open {
  transform: translateX(0);
}
.side__right.ReactModal__Content--before-close{
  transform: translateX(100%);
}

.side__top {
  top: 0px;
}
.side__top.ReactModal__Content {
  transform: translateY(-100%);
  transition: transform 300ms ease;
}
.side__top.ReactModal__Content--after-open {
  transform: translateY(0);
}
.side__top.ReactModal__Content--before-close{
  transform: translateY(-100%);
}

.side__bottom {
  bottom: 0px;
}
.side__bottom.ReactModal__Content {
  transform: translateY(100%);
  transition: transform 300ms ease;
}
.side__bottom.ReactModal__Content--after-open {
  transform: translateY(0);
}
.side__bottom.ReactModal__Content--before-close{
  transform: translateY(100%);
}


.border__radius.side__bottom {
  border-radius: 10px 10px 0 0;
}
.border__radius.side__top {
  border-radius: 0 0 10px 10px;
}

.border__radius.side__left {
  border-radius: 0 10px 10px 0;
}
.border__radius.side__right {
  border-radius: 10px 0 0 10px;
}

.modalContent.side__left,
.modalContent.side__right,
.modalContent.side__top,
.modalContent.side__bottom {
  width: 100%;
  height: 100%;
}

.dialogContent.side__top,
.dialogContent.side__bottom {
  width: 100%;
}

.dialogContent.side__left,
.dialogContent.side__right {
  width: 40%;
  min-width: 560px;
  height: 100%;
}
.width-500 {
  width: 515px !important;
  min-width: 515px !important;
}

$orders: 1, 2, 3, 4, 5;
@each $order in $orders {
  .overlayOrder-#{$order} {
    z-index: 1500 + $order;
  }
}
