@import "topography";

.swiper {
  display: flex;
  flex-direction: column;
}

.swiper > .swiper-pagination-fraction {
  display: none !important;
}

.swiper-button-next,
.swiper-button-prev {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAYAAABjPNNTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIySURBVHgBzZmPVcIwEMavPgdghDqBbiA+F9AF1A1wA+oC6gTiBm6gOIB/WACcAJiA8z7SYi2k7YUk8HvvexWbeh93SZrEhBxh5q5cjkW4nog6ucBc9JNrJBomSTKkGIixjqgvmrGeiWggSikEubkH9sfAq1n5Yz12y1wTY9E1bQv7zZ6Ne3KBTXnfOB6fiKk1+cLxedUYdCmxrz7bXHppdMN6Mjbd44u3ZyG6rTOYspnLVAZLz3fZD1O29U+58cw6ygZP2O80dW/L4r4YBCh7Z5sshja4FqcINtE+GNggmJJDh49pEKDkXcQ7ILPUauJOllpZYVAub/S3LAtFQrk3mDxuaLwLgwXGG9dPwlnROlKJq4wR+1CUWr7FKoM5WFbNc4UirXw2FbN8g4wiIvGgow0+Frh/YHuO9giUG+WrDgQsGkjKfVf8Qj735HJB4dg0GOdF8ElNx+2XTO5i4HwiNsr9TXaywqhkFe3OKOzAqbKMBZOjhoa7Mopx8W5+av9ajF16vBZPqRS0bcCYRsf/82q2AG2JZfSpalK76A1tFKVO13upfvsQyigMPm0eSmbHpw1UNeoDHL+kZENu3rKefv7sgLcHWbyiJqTRI+vxted+oLZw3HOggg+bH9sq6JLqX5c+wZsFq/1zcoHdSq9BV+IaozesP35pYw7HKT3yBZvJXjuP2swts8fa80gHs5P2vlbGkLm+i7mEHGGzcYew7Uxzlf9FMiMz+IaikSzz3smRX+zXS01Q5GTHAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  width: 40px !important;
  height: 40px !important;
  margin-top: calc(0px - 40px / 2) !important;
  visibility: hidden;

  &::after {
    content: "" !important;
  }
}

@media (hover: hover) and (pointer: fine) {
  .swiper:hover > .swiper-button-next,
  .swiper:hover > .swiper-button-prev {
    visibility: visible;
  }
}

.swiper-button-prev {
  left: 20px !important;
}

.swiper-button-next {
  transform: scale(-1);
  right: 20px !important;
}

.swiper > .swiper-wrapper {
  position: relative;
  order: 1;
}

.swiper > .swiper-pagination {
  order: 2;
  position: relative;
  bottom: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px 0 0 0;
  padding: 0;
}

.swiper-pagination > .swiper-pagination-bullet {
  flex-grow: 1;
  margin: 0 (0.05rem * 3);
  padding: 0;
  height: (0.05rem * 3);
  border-radius: (0.05rem * 2);

  display: block;
  cursor: pointer;
  opacity: 1;
  background-color: $black;
}

.swiper-pagination > .swiper-pagination-bullet.bold-dots {
  // margin-top: (0.05rem * 3);
  height: (0.05rem * 4);
}

.swiper-pagination > .swiper-pagination-bullet.dark-mode {
  background-color: $white;
}

.swiper-pagination > .swiper-pagination-bullet-active {
  background-color: $yellow !important;
}