/*! rtl:begin:ignore */
.fadeMobile-enter,
.fadeMobile-exit,
.fadeDesktop-enter, 
.fadeMobile-exit {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
/*! rtl:end:ignore */

/*Mobile section*/
.fadeMobile-enter {
  transform: translateX(-100%);
}
.fadeMobile-enter-active {
  transform: translateX(0%);
  transition: transform 300ms ease;
}
.fadeMobile-exit {
  transform: translateX(0%);
}
.fadeMobile-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease;
}

/*Desktop section*/
.fadeDesktop-enter {
  opacity: 0.0;
}
.fadeDesktop-enter-active {
  opacity: 1.0;
  transition: opacity 300ms ease;
}
.fadeDesktop-exit {
  opacity: 1.0;
}
.fadeDesktop-exit-active {
  opacity: 0.0;
  transition: opacity 200ms ease;
}