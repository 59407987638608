
html {
  font-size: 20px;
}

p {
  letter-spacing: -0.03em;
}

button {
  padding: 0;
  margin: 0;
  border: none;
  font-family: "Aeroport";
  background: none;
  color: #ffffff;
  &:focus {
    outline: none;
  }
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  min-width: 320px;
  overflow-x: hidden;
}

.navbar {
  padding: 0 15px 0 20px;
};

.container {
  padding-right: 0;
  padding-left: 0;
};

input {
  border-radius: 0;
};

input:disabled {
  background-color: #ffffff;
  border-radius: 0;
}


.terms_1_1_1 {
  padding-left: (0.05rem*30);
  p {
    margin-top: (0.05rem*10) !important;
    @include f-size(18, 24);
  }
}