$white: #ffffff !default;
$black: #000000 !default;
$clear: #fcc4c400 !default;

$yellow: #FFB800 !default;
$yellow-2: #E5B350 !default;

$lite-gray: #E5E5E5 !default;
$gray: #E1E7EB !default;
$dark-gray: #B9C2C9 !default;

$pink: #F14165 !default;
$pink-2: #CF4A5E !default;
$red: red;

@mixin f-size($size, $height, $bold: false) {
  font-size: (0.05rem*$size);
  line-height: (0.05rem*$height);
  @if $bold {font-weight: 700;}
}

@mixin bottom-dot-native() {
  text-decoration: underline;
  text-decoration-style: dotted;
}
@mixin bottom-dot() {
  padding-bottom: (0.05rem*2);
  border-bottom: 0.08rem;
  border-bottom-style: dotted;
}
@mixin bottom-dot-pro() {
  background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 0.15rem 0.08rem;
  background-repeat: repeat-x;
}
@mixin bottom-dot-pro-white() {
  background-image: linear-gradient(to right, white 33%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 0.15rem 0.08rem;
  background-repeat: repeat-x;
}
@mixin bottom-dot-thin-pro() {
  background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 0.15rem 0.05rem;
  background-repeat: repeat-x;
}
@mixin bottom-dot-bold($color: false) {
  padding-bottom: (0.05rem*6);
  border-bottom: 0.1rem;
  border-bottom-style: dotted;
  @if $color {border-bottom-color: $color;}
}
@mixin bottom-dot-bold-pro() {
  background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 0.2rem 0.1rem;
  background-repeat: repeat-x;
}
@mixin bottom-dot-lite-pro() {
  background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 0.15rem 0.05rem;
  background-repeat: repeat-x;
}

@mixin text-shadow($let-space: 2) {
  color: $white;
  text-shadow: $black 1px 1px 0, $black -1px -1px 0, 
                $black -1px 1px 0, $black 1px -1px 0; 
  letter-spacing: (1px*$let-space);
}

@mixin overflow-hide($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
}
