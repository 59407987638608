/*! rtl:begin:ignore */
@import "bootstrap/scss/_functions.scss";
@import "_variables.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
@import "bootstrap/scss/_root.scss";
@import "bootstrap/scss/_reboot.scss";
@import "_reboot.scss";
/*! rtl:end:ignore */
@import "bootstrap/scss/_type.scss";
// @import "bootstrap/scss/_images.scss";
// @import "bootstrap/scss/_code.scss";
@import "bootstrap/scss/_grid.scss";
// @import "bootstrap/scss/_tables.scss";
// @import "bootstrap/scss/_forms.scss";
@import "bootstrap/scss/_buttons.scss";
@import "bootstrap/scss/_transitions.scss";
// @import "bootstrap/scss/_dropdown.scss";
@import "bootstrap/scss/_button-group.scss";
// @import "bootstrap/scss/_input-group.scss";
// @import "bootstrap/scss/_custom-forms.scss";
@import "bootstrap/scss/_nav.scss";
@import "bootstrap/scss/_navbar.scss";
// @import "bootstrap/scss/_card.scss";
// @import "bootstrap/scss/_breadcrumb.scss";
// @import "bootstrap/scss/_pagination.scss";
// @import "bootstrap/scss/_badge.scss";
// @import "bootstrap/scss/_jumbotron.scss";
// @import "bootstrap/scss/_alert.scss";
@import "bootstrap/scss/_progress.scss";
// @import "bootstrap/scss/_media.scss";
// @import "bootstrap/scss/_list-group.scss";
// @import "bootstrap/scss/_close.scss";
// @import "bootstrap/scss/_toasts.scss";
// @import "bootstrap/scss/_modal.scss";
// @import "bootstrap/scss/_tooltip.scss";
// @import "bootstrap/scss/_popover.scss";
// @import "bootstrap/scss/_carousel.scss";
@import "bootstrap/scss/_spinners.scss";
@import "_utilities.scss";
// @import "bootstrap/scss/_utilities.scss";
// @import "bootstrap/scss/_print.scss";
@import "font-&-styles.pure.scss";


.bg-warning {
  background-color: #FFB800;
}

a {
  text-decoration: none !important;
}

// This style fix bug in react-pure-carousel (black outline)
.focusRing___1airF.carousel__slide-focus-ring { outline: none !important; }