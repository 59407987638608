$paragraph-margin-bottom:     0 !default;
$headings-margin-bottom:      0 !default;
$headings-font-weight:        400 !default;

$font-family-base:            "Aeroport" !default; 
$font-size-base:              1rem !default;
$font-weight-base:            400 !default;
$line-height-base:            1.15 !default;
$body-color:                  #000000 !default;
$body-bg:                     #ffffff !default;

$link-color:                  #000000 !default;
$link-hover-color:            #000000 !default;

$grid-gutter-width:           0 !default;

$spacer: 1rem !default;

$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    101: ($spacer * 0.05),     //1px
    102: ($spacer * 0.10),     //2px
    103: ($spacer * 0.15),     //3px
    104: ($spacer * 0.20),     //4px
    106: ($spacer * 0.3),      //6px
    108: ($spacer * 0.4),      //8px
    1: ($spacer * 0.25),       //5px
    2: ($spacer * 0.5),       //10px
    3: ($spacer * 0.75),      //15px
    4: ($spacer * 1.0),       //20px
    
    5: ($spacer * 1.25),      //25px
    6: ($spacer * 1.5),       //30px
    7: ($spacer * 1.75),      //35px
    8: ($spacer * 2.0),       //40px
    9: ($spacer * 2.25),      //45px
    10: ($spacer * 2.50),      //50px

    55: ($spacer * 2.75),     //55px
    60: ($spacer * 3),        //60px
    75: ($spacer * 3.75),     //75px
    80: ($spacer * 4),        //80px
    90: ($spacer * 4.5),      //90px
    100: ($spacer * 5),       //100px
    120: ($spacer * 6),       //120px
    155: ($spacer * 7.75),    //155px
    160: ($spacer * 8),       //160px
    200: ($spacer * 10),       //200px
    250: ($spacer * 12.5)     //250px
  ),
  $spacers
);

// Grid breakpoints
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 560px,
  md: 768px,
  lg: 1200px,
  xl: 1400px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 990px,
  xl: 1200px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

//Color system
@import "./topography.scss";


$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "white":      $white,
    "black":      $black,
    "gray":       $gray,
    "$dark-gray": $dark-gray,
    "pink":       $pink,
    "pink-2":     $pink-2
  ),
  $colors
);

// Shadow block
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;